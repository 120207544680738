<template>
  <div :class="$style.item">
    <img :class="$style.img" :src="data.icon" />
    <div :class="$style.content">
      <div :class="$style.name">
        {{ data.name }}
        <div :class="$style.tag">{{ data.is_effective | stateName }}</div>
      </div>
      <div :class="$style.text" :title="data.summary" class="ellipsis-width">
        {{ data.summary }}
      </div>
      <BjButton type="primary" @click="onAdd()">
        <i class="ri-bank-card-line" />
        续费服务
      </BjButton>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  filters: {
    stateName(val) {
      return val ? '有效期' : '已过期'
    },
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    onAdd() {
      this.$emit('add', this.data)
    },
  },
}
</script>

<style lang="less" module>
.item {
  width: 100%;
  height: 142px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 20px;
  display: flex;

  img {
    width: 50px;
    height: 50px;
    margin-right: 20px;
  }

  .name {
    font-size: 16px;
    margin-bottom: 6px;
  }

  .text {
    font-size: 12px;
    color: #5c5c5c;
    margin-bottom: 20px;
  }

  .tag {
    float: right;
    width: 60px;
    height: 24px;
    font-size: 12px;
    text-align: center;
    border: 1px solid #eee;
    color: rgba(92, 92, 92, 100);
    background: #fafafa;
    border-radius: 100px;
    line-height: 24px;
  }
}
</style>
