<template>
  <div :class="$style.home">
    <div :class="$style.haeder">
      <div
        v-for="item in head"
        :key="item.id"
        :class="[active === item.id ? $style.active : null, $style.item]"
        @click="onChoose(item.id)"
      >
        {{ item.name }}
      </div>
    </div>
    <div :class="$style.box">
      <a-row :gutter="[20, 20]">
        <a-col
          v-for="item in list"
          :key="item.id"
          :sm="{ span: 12 }"
          :md="{ span: 12 }"
          :lg="{ span: 12 }"
          :xl="{ span: 8 }"
          :xxl="{ span: 6 }"
        >
          <Item :data="item" @add="onAdd" />
        </a-col>
      </a-row>
    </div>
    <div class="pagination">
      <a-pagination
        :current="page"
        show-size-changer
        :show-total="total => `共 ${total} 条`"
        :page-size.sync="pageSize"
        :total="total"
        @showSizeChange="onShowSizeChange"
        @change="onPageChange"
      />
    </div>

    <bj-modal title="订购应用" :visible="visible" :width="800" :body-style="{ height: '400px' }" @cancel="handleCancel">
      <div :class="$style.modal">
        <div :class="$style.tip">
          应用服务的订购时长最长可以与版本时长保持同步，最小可以选择按周或按月订购，应用服务到期后，前台将禁止用户访问，后台将不能添加新的数据。
        </div>
        <div v-if="data.sale_type === 1" :class="$style.box">
          <div :class="$style.boxLeft">
            <span class="required">*</span>
            订购时长
          </div>
          <div :class="$style.boxRight" class="pr-20">
            <a-slider
              v-model="infoNum"
              :tooltip-visible="false"
              :marks="marks"
              :min="1"
              :max="9"
              @afterChange="onAfterChange"
            />
          </div>
        </div>
        <div v-if="data.sale_type === 2">
          <div v-for="(item, index) in data.spec" :key="index" :class="$style.box">
            <div :class="$style.boxLeft">
              <span class="required">*</span>
              {{ item.name }}
            </div>
            <div :class="$style.boxRight">
              <sku v-model="sku[index]" :data="item.value" @change="skuChange" />
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <div :class="$style.footer">
          <div :class="$style.price">订购费用：￥{{ info.price }}</div>
          <div :class="$style.btns">
            <BjButton type="primary" :disabled="!info.is_valid" :tooltips="info.message" @click="handleOk">
              添加到购物车
            </BjButton>
            <BjButton :class="$style.cannel" @click="handleCancel">取消</BjButton>
          </div>
        </div>
      </template>
    </bj-modal>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

import { centerServiceService } from '@/service'

import Item from './item.vue'

const service = new centerServiceService()

export default {
  name: 'Home',
  components: {
    Item,
  },
  data() {
    return {
      list: [],
      data: {},
      visible: false,
      head: [
        {
          name: '全部服务',
          id: 0,
        },
        {
          name: '有效期',
          id: 1,
        },
        {
          name: '已过期',
          id: 2,
        },
      ],
      active: 0,
      page: 1,
      pageSize: 10,
      total: 0,
      info: {},
      sku: [],
      infoNum: 1,
      marks: {
        1: '1周',
        2: '1个月',
        3: '2个月',
        4: '3个月',
        5: '半年',
        6: '1年',
        7: '2年',
        8: '3年',
        9: '与版本同步',
      },
      type: {
        1: {
          time_unit: 1,
          time_num: 1,
        },
        2: {
          time_unit: 2,
          time_num: 1,
        },
        3: {
          time_unit: 2,
          time_num: 2,
        },
        4: {
          time_unit: 2,
          time_num: 3,
        },
        5: {
          time_unit: 2,
          time_num: 6,
        },
        6: {
          time_unit: 3,
          time_num: 1,
        },
        7: {
          time_unit: 3,
          time_num: 2,
        },
        8: {
          time_unit: 3,
          time_num: 3,
        },
        9: {
          time_unit: 4,
          time_num: 1,
        },
      },
    }
  },
  created() {
    this.getList()
  },
  methods: {
    ...mapMutations(['REFRESHSHOPCAR']),
    onAdd(data) {
      this.getInfo(data.service_id)
    },
    async getInfo(id) {
      try {
        const { data } = await service.getDetail({
          id: Number(id),
        })
        this.data = data
        if (this.data.sale_type === 1) {
          this.infoNum = 1
          this.onAfterChange()
        } else {
          this.sku = []
          this.data.spec.map(item => {
            this.sku.push(item.value[0].id)
          })
          this.skuChange()
        }
        this.visible = true
      } catch (e) {}
    },
    async getList() {
      try {
        const { data } = await service.getUserList({
          status: this.active,
          page: this.page,
          page_size: this.pageSize,
        })
        this.list = data.record
        this.total = data.total
      } catch (e) {}
    },
    onChoose(type) {
      this.active = type
      this.getList()
    },
    onPageChange(page) {
      this.page = page
      this.getList()
    },
    onShowSizeChange() {
      this.page = 1
      this.getList()
    },
    handleCancel() {
      this.visible = false
    },
    async handleOk() {
      try {
        if (this.data.sale_type === 1) {
          let time_unit = this.type[this.infoNum].time_unit
          let time_num = this.type[this.infoNum].time_num
          await service.pushCar({
            foreign_type: 3, // 商品类型 1-版本 2-应用 3-服务
            foreign_key_id: Number(this.data.id),
            time_unit: time_unit,
            time_num: time_num,
          })
          this.REFRESHSHOPCAR(Math.random())
          this.visible = false
          this.$message.success('添加成功')
        }
        if (this.data.sale_type === 2) {
          await service.pushCar({
            foreign_type: 3, // 商品类型 1-版本 2-应用 3-服务
            foreign_key_id: Number(this.data.id),
            spec_value_id: this.sku,
          })
          this.REFRESHSHOPCAR(Math.random())
          this.visible = false
          this.$message.success('添加成功')
        }
      } catch (e) {}
    },
    async onAfterChange() {
      try {
        if (this.data.sale_type === 1) {
          let time_unit = this.type[this.infoNum].time_unit
          let time_num = this.type[this.infoNum].time_num
          const { data } = await service.getPrice({
            foreign_type: 3,
            foreign_key_id: Number(this.data.id),
            time_unit: time_unit,
            time_num: time_num,
          })
          this.info = data
        }
      } catch (e) {}
    },
    async skuChange() {
      const { data } = await service.getPrice({
        foreign_type: 3,
        foreign_key_id: Number(this.data.id),
        spec_value_id: this.sku,
      })
      this.info = data
    },
  },
}
</script>

<style lang="less" module>
.home {
  .haeder {
    display: flex;
    margin-bottom: 20px;

    .item {
      font-size: 16px;
      margin-right: 30px;
      cursor: pointer;
    }

    .active {
      color: @primary-color !important;
    }
  }
}

.modal {
  .tip {
    width: 100%;
    padding: 10px;
    color: #5c5c5c;
    background: #fafafa;
    font-size: 12px;
    margin-bottom: 20px;
  }

  .box {
    width: 100%;
    display: flex;
    margin-bottom: 20px;

    &-left {
      width: 90px;
      line-height: 54px;
    }

    &-right {
      flex: 1;
    }
  }
}

.footer {
  display: flex;
  align-items: center;

  .price {
    width: 50%;
    text-align: left;
    font-size: 12px;
  }

  .btns {
    flex: 1;
    text-align: right;
  }

  .cannel {
    margin-left: 10px !important;
  }
}
</style>
